<template>
  <div class="compliance-progress-component">
    <div class="section cp-section1">
      <div class="page1-main public-main">
        <img src="./img/section-img1.jpg" alt="" class="img" />
      </div>
    </div>
    <div class="section cp-section2">
      <div class="page2-main public-main">
        <img src="./img/section-img2.jpg" alt="" class="img" />
      </div>
    </div>
    <div class="section cp-section-add">
      <div class="page-add-main public-main">
        <img src="./img/section-img3.jpg" alt="" class="img" />
      </div>
    </div>
    <div class="section cp-section3">
      <div class="page3-main public-main">
        <img src="./img/section-img4.jpg" alt="" class="img" />
      </div>
    </div>
    <div class="section cp-section4">
      <div class="page4-main public-main">
        <img src="./img/section-img4.jpg" alt="" class="img" />
      </div>
    </div>
    <div class="section cp-section5">
      <div class="page5-main public-main">
        <img src="./img/section-img5.jpg" alt="" class="img" />
      </div>
    </div>
    <div class="section cp-section6">
      <div class="page6-main public-main">
        <img src="./img/section-img6.jpg" alt="" class="img" />
      </div>
    </div>
    <div class="section cp-section7">
      <div class="page7-main public-main">
        <img src="./img/section-img7.jpg" alt="" class="img" />
      </div>
    </div>
    <div class="section cp-section8">
      <div class="page8-main public-main">
        <img src="./img/section-img8.jpg" alt="" class="img" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ComplianceProgress',
  data() {
    return {}
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.compliance-progress-component .img {
  width: 100%;
  margin-top: -1px;
}
</style>
